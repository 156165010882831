



















export default {
  mounted() {
    this.$nextTick(() => {
      if ((window as any).twttr) {
        ;(window as any).twttr.widgets.load()
      }
    })
  },
}
